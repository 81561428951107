.DropdownMenu {
	width: 120px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: lighter;
	font-size: 15px;
	transition: transform 0.3s ease-out;
	cursor: pointer;
	
	.icon { 
		position: fixed;
		right: 70px;
		top: 15px;
	}


	.dropBar {
		position: fixed;
		top: 70px;
		left: 0;
		background: #000000;
		width: 100%;

		ul {
			text-align: center;
			padding: 0;
			margin: 0;
			:hover {
				color: #fff;
				background: #002672;
			}
		}

		.login {
			color: #ff2672;
		}
	}
}

.Open {
	transform: translateX(0);
}


.Close {
	transform: translateX(100%);
}

