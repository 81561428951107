.main_footer {
  padding: 0 0 0 0;
  font-family: 'Ubuntu', sans-serif;
  color: #f7f7f7;
  text-align: center;
  .box {
    text-align: center;
    padding: 10px 10px;
    font-size: 15px;
    .icon {
      display: inline-block;
    }
  }

  a { padding: 20px; }
}

// Display icons 4x2 within this range
@media (max-width: 576px) and (min-width: 300px) {
  .box { width: 300px; margin: auto;}
}