.UserRanking {
  width: 260px;
  height: 230px;
  right: 0;
  margin-top: 120px;
  padding: 0px 30px;
  background: #f7f7f7;
  border: 0px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: -1;
  position: fixed;
  overflow: auto;

  h3 {
    text-decoration: underline;
  }

  button {
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    background-color: transparent;
    cursor: context-menu;
    border: none;
    border-radius: 5px;
    border-left: #ddd solid 3px;
    border-right: #ddd solid 3px;
    width: 100px;
    color: #002672;

    &:hover {
      background-color: #ddd;
    }
    &:focus {
      outline: 0;
    }

  }

  table {
    width: 100%;
    padding: 0px 20px;
    
    th {
      text-emphasis-style: bold;
      text-align: left;
    }
    
    td {
      text-align: right;
      padding: 10px;
    }
  }
}