.Categories {
  color: #000;
  background-color: #f7f7f7;
  position: fixed;
  width: 50%;
  margin-top: 250px;
  padding: 10px 20px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: -1;
}