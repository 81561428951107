.login {
  margin: 125px 75px;
  padding: 25px 50px;
  width: 50%;
  height: 425px;
  background-color: #f7f7f7;
  position: fixed;
  border-left: solid #f44336 15px;
  overflow: auto;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 40px;

  table{
    font-size: 15px;
    float: left;
    td{
      text-align:left;
    }
  }
  
  input[type=text], input[type=password] {
    margin: 8px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius:10px;
    &:focus {outline: 0}
  }
  
  button {
    background-color: white;
    border: 2px solid #e7e7e7;
    border-radius: 10px;
    font-size: 20px;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    padding: 20px 30px;

    &:hover { background-color: #e7e7e7; }
    &:focus { outline:0; }
  }
}