.notFound {
  width: 80%;
  height: 100vh;
  color: #f7f7f7;
  font-family: 'Ubuntu', sans-serif;
  padding: 10%;
  position: fixed;
  background: #000000;
  background: url(../../../asset/images/IMG_0004.JPG) no-repeat 50% 50%;
  background-attachment: fixed;
  background-position: center; 
  background-size: cover;

  h1 { font-size: 100px; }
  h2 { font-style: italic; }

  button {
    font-family: 'Ubuntu', sans-serif;
    border: none;
    border-radius: 10px;
    margin: 20px 0px;
    padding: 10px 30px;
    cursor: pointer;
    &:hover { background-color: #ddd; color: #ff2672; }
    &:focus { outline: 0; }
  }
}

// Tablet
@media (max-width: 1025px) and (min-width: 501px) {
  .notFound {
    padding: 20% 10%;
    h1 { font-size: 80px; }
    h2 { font-size: 20px; }
  }
}

// Mobile
@media (max-width: 500px) {
  .notFound {
    padding: 30% 10%;
    h1 { font-size: 70px; }
    h2 { font-size: 15px; }
  }
}