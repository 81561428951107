/* STYLING BODY */
body { 
	width: 100%;
	background: #272822;
	// background: url(../../asset/images/IMG_9544.JPG) no-repeat 50% 50%;
	// background: url(../../asset/images/IMG_0101.JPG) no-repeat 50% 50%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
	font-family: 'Ubuntu', sans-serif;
}