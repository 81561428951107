.modal {
  position: fixed; left: 0; top: 70px;
  width: 100%; height: 100vh; z-index: 1;
  background-color: rgba(0,0,0,0.4);
  font-family: 'Ubuntu', sans-serif; 
}

.box {
  position: fixed; left: 20%; top: 27%;
  width: 60%; height: 55%;
  z-index: 2;
  color: #000;
  border-radius: 20px;
  padding: 20px;
  overflow: auto;

  background: url(../../../../asset/images/background_people_2.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  h5 {
    font-size: 20px;
    span { color: #192a69; font-size: 12px; }
  }

  h6 { color: #0e236e; font-size: 18px; }

  p { 
    font-size: 15px;
    line-height: 1.2em;
    text-align: justify;
    // font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    // font-family: 'Ubuntu', sans-serif;
    font-family: 'Noto Sans', sans-serif;
  }

}

.block { 
  display: inline-block; 
  padding: 25px 10px;
  text-align: left;
  p { width: 400px;}
}

.show { display: block; }
.hide { display: none; }

// Tablet
@media (max-width: 1025px) and (min-width: 501px) {
  .box { width: 80%; left: 10%;  height: 70%; top: 15%; padding: 0;}
  .block {
    padding: 5% 5%;
    p { width: 100%; }
  }
}

// Mobile
@media (max-width: 500px) {
  .box { width: 100%; left: 0;  height: 70%; top: 15%; padding: 0;}
  .block {
    padding: 5% 5%;
    p { width: 100%; }
  }
}