.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0,0,0,0.4);
}

.box {
  h1 { text-align: center; }
  
  position: fixed;
  width: 50%;
  height: 60%;
  top: 20%;
  left: 20%;
  z-index: 2;
  background-color: #f7f7f7;
  color: #000;
  border-radius: 20px;
  padding: 20px 60px;
  line-height: 1.1em;
  text-align: left;
  overflow: auto;
}


.show {
  display: block;
}

.hide {
  display: none;
}