table{
  margin:auto;
  padding:auto;
  
  td{
    text-align:right;
  }
}

input[type=text], input[type=password] {
  margin: 8px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius:10px;
  &:focus {outline: 0}
}

.help a{
  color:#9a92ff;
  text-decoration: underline;
  font-size: 11px;

  &:hover{
    opacity: 0.8;
  }
}

.button {
  background-color: #ff2672;
  border-radius:10px;
  color: #f7f7f7;
  padding: 10px 100px;
  margin: 8px 0;
  border: none;
  font-family: 'Ubuntu', sans-serif;
  cursor: pointer;


  &:hover {
    opacity: 0.8;
    color: #f7f7f7;
  }
  &:focus {outline: 0}
}

.newuser {
  display: inline-block;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 20%;
    top: 50%;
    left: 100%;
    background: #f7f7f7;
  }

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 20%;
    top: 50%;
    right: 100%;
    background: #f7f7f7;
  }
  
}

