.myCourses {
  color: #fff;
  width: 70%;
  padding: 10px 20px;
  position: absolute;
  margin-top: 100px;
  z-index: -1;
}

.courseBar {
  width: 200px;
  height: 200px;
  padding: 20px;
  display: inline-block;
  
  button {
    font-family: 'Ubuntu', sans-serif;
    background: transparent;
    border: none;
    cursor: pointer;
    
    &:active { border: none; }
    &:focus { outline:0; }
  }
}