.blog {
  margin-top: 35px;
  width: 100%;
  height: height;
  padding: 5px 0 90px 0;
  color: #f7f7f7;
  background: #272822;
  font-size: 20px;
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  background: url(../../../asset/images/background_people.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  attachment: fixed;
  align-items: center;
  justify-content: center;

  .pagination {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  
    & > li {
        margin: 0 20px;
        list-style-type: none;
        & > a {
            position: relative;
            font-size: 1.5rem;
            width: 40px;
            height: 40px;
            outline: none;
            z-index: 100;
            cursor: pointer;
  
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                z-index: -100;
            }
  
            &:hover {
                &::before {
                    background-color: rgba(80, 80, 80, 0.5);
                }
            }
        }
  
        &.active {
            & > a::before {
                background-color: rgba(80, 80, 80, 0.5);
            }
        }
    }
  
    &__previous,
    &__next,
    &__break,
    &__link {
        & > a {
            font-size: 0.7rem;
        }
    }
  
    &__disabled {
        display: none;
    }
  }

  _::-webkit-full-page-media, _:future, :root .entry{
    background-color: rgba(0,0,0,0.9);
    display: block;
    margin: 0 auto;
    width: 80%;
    color: #f7f7f7;
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    text-align: center;
    border: none;
    border-radius: 20px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    _::-webkit-full-page-media, _:future, :root .thumbnail{
      width: 550px;
      aspect-ratio: 8/5;
      border-radius: 20px;
    }
  }
  .entry{
    background-color: rgba(0,0,0,0.9);
    display: block;
    margin: 0 auto;
    width: 80%;
    color: #f7f7f7;
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    text-align: center;
    border: none;
    border-radius: 20px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    .thumbnail{
      width: 550px;
      aspect-ratio: 8/5;
      border-radius: 20px;
    }
    h3{
      padding-top: 1vh;
      font-size: 30px;
    }
    p{
      margin-bottom: 20px;
    }
    .p1{
      text-color: #f7f7f7;
      margin-bottom: 20px;
    }
    .round_btn {
      display: inline-block;
      position: relative;
      top: 10px;
      right: -50%;
      width: 30px;
      height: 30px;
      border: 2px solid #333;
      border-radius: 50%;
      background: #fff;
      transition: all 1s; /* アニメーション設定 */
    }
     
    .round_btn::before, .round_btn::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 22px;
      background: #333;
    }
     
    .round_btn::before {
      transform: translate(-50%,-50%) rotate(45deg);
    }
     
    .round_btn::after {
      transform: translate(-50%,-50%) rotate(-45deg);
    }
     
    .round_btn:hover { /* マウスオーバー時のスタイル */
        transform: rotate(180deg);
    }
  }

  .switch{
    margin-top: 30px;
    margin-bottom: 45px;
    width: 45%;
    color: #272822;
    font-family: 'Ubuntu', sans-serif;
    font-size: 30px;
    text-align: center;
    border: none;
    border-radius: 20px;
    // border: red 1px solid;
    cursor: pointer;
    &:hover { background-color: #002672; }
    &:hover { color: yellow; }
    &:focus { outline: 0; }
  }

  h1 {
    padding-top: 1vh;
    text-align: center;
    color:  #f7f7f7;
  }
  .article {
    margin: 20px;
    height: 300px;
    width: 300px;
    border-radius: 10px;
    border: none;
    display: inline-block;
    background-color: #f7f7f7;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    &:hover { background-color: #aaa; }
    &:focus { outline: 0; }

  }

  button{
    top: -10%;
    left: 10%;
  }

  
}

_::-webkit-full-page-media, _:future, :root .post {
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 560px;

  _::-webkit-full-page-media, _:future, :root button {
    display: inline-block;
    border: none;
    background: transparent;
    cursor: pointer;
    height: 350px;
    border-radius: 20px;
    &:focus{ outline: 0; }
    .image { border-radius: 20px; }
  }

  _::-webkit-full-page-media, _:future, :root .image { width: 550px; aspect-ratio: 8/5; }

  _::-webkit-full-page-media, _:future, :root .imgName {
    display: inline-block;
    visibility: visible;
    opacity: 1;
  }
}

.post {
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 560px;

  button {
    display: inline-block;
    border: none;
    background: transparent;
    cursor: pointer;
    height: 350px;
    border-radius: 20px;
    &:focus{ outline: 0; }
    .image { border-radius: 20px; }
  }

  .image { width: 550px; aspect-ratio: 8/5; }

  .imgName {
    display: inline-block;
    visibility: visible;
    opacity: 1;
  }
}

.sideBar {
  z-index: 10;
  width: 400px;
  background-color: #6B6B6B6B;
  float: left;
  top: 20%;
  overflow: auto;
  padding: 10px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  animation-name: run;
  animation-duration: 0.5s;
  text-align: center;
  position: fixed;
  height: 100%;


  .buttonUni{
    position: relative;
    margin: 10px;
    left: -10px;
    width: 380px;
    padding: 10px;
    color: #f7f7f7f7;
    background-color: black;
    font-family: 'Ubuntu', sans-serif;
    font-size: 15px;
    text-align: left;
    border: none;
    // border: red 1px solid;
    border-radius: 10px;
  }

  button {
    margin: 5px;
    width: 380px;
    color: black;
    font-family: 'Ubuntu', sans-serif;
    font-size: 15px;
    text-align: center;
    border: none;
    border-radius: 5px;
    // border: red 1px solid;
    cursor: pointer;
    &:hover { background-color: #ff2672; }
    &:focus { outline: 0; }
  }
}

@keyframes run {
  0% { left: -20%;}
  100%{ left: 0;}
}

_::-webkit-full-page-media, _:future, :root .post p{
  display: inline-block;
  text-align: center;
  position: absolute;
  background-color: rgba(80, 80, 80, 0.5);
  color: #ffffff;
  font-size: 12px;
  font-family: 'Ubuntu', sans-serif;
  bottom: 5%;
  left: 4%;
  right: 1%;
  border-radius: 15px;
  margin: auto;
  width: 90%;
}

.post p{
  display: inline-block;
  text-align: center;
  position: absolute;
  background-color: rgba(80, 80, 80, 0.5);
  color: #ffffff;
  font-size: 15px;
  font-family: 'Ubuntu', sans-serif;
  bottom: 4.5%;
  left: 1%;
  right: 0.5%;
}

// Mobile
@media (max-width: 1119px) {
  _::-webkit-full-page-media, _:future, :root .post {
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 95%;
  
    _::-webkit-full-page-media, _:future, :root p{
      border-radius: 15px;
      margin: auto;
      width: 90%;
      bottom: 15%;
      font-size: 8px;
      -webkit-text-size-adjust: 100%;
      bottom: 5%;
      left: 1%;
      right: 10%;
    }

    _::-webkit-full-page-media, _:future, :root button {
      display: inline-block;
      border: none;
      background: transparent;
      cursor: pointer;
      height: 100%;
      border-radius: 10px;
      &:focus{ outline: 0; }
      .image { border-radius: 10px; }
    }
  
    _::-webkit-full-page-media, _:future, :root .image 
    { width: 90vw; 
      border-radius: 10px;
    }
  
    _::-webkit-full-page-media, _:future, :root .imgName {
      display: inline-block;
      visibility: visible;
      opacity: 1;
    }
  }

  .post {
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 95%;
  
    p{
      border-radius: 15px;
      margin: auto;
      width: 90%;
      bottom: 15%;
      left: 1%;
      right: 1.55%;
    }

    button {
      display: inline-block;
      border: none;
      background: transparent;
      cursor: pointer;
      height: 100%;
      border-radius: 10px;
      &:focus{ outline: 0; }
      .image { border-radius: 10px; }
    }
  
    .image 
    { width: 90vw; 
      border-radius: 10px;
    }
  
    .imgName {
      display: inline-block;
      visibility: visible;
      opacity: 1;
    }
  }

  _::-webkit-full-page-media, _:future, :root h1 {
    padding: 10%;
    font-size: 20px;
    text-align: center;
    color:  #f7f7f7;
  }

  h1 {
    padding: 10%;
    font-size: 20px;
    text-align: center;
    color:  #f7f7f7;
  }

  _::-webkit-full-page-media, _:future, :root .blog{
    _::-webkit-full-page-media, _:future, :root .entry{
      width: 90%;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      _::-webkit-full-page-media, _:future, :root h3{
        padding-top: 0;
        font-size: 15px;
      }
      _::-webkit-full-page-media, _:future, :root p{
        margin-bottom: 10px;
      }
      _::-webkit-full-page-media, _:future, :root .thumbnail{
        width: 95%;
        border-radius: 10px;
      }
    }
  }

  .blog{
    .entry{
      width: 90%;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      h3{
        padding-top: 0;
        font-size: 15px;
      }
      p{
        margin-bottom: 10px;
      }
      .thumbnail{
        width: 95%;
        border-radius: 10px;
      }
    }
  }

  .sideBar {
    width: 300px;
    top: 20%;
    overflow: auto;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    animation-name: run;
    animation-duration: 0.5s;
    text-align: center;
    position: fixed;
    height: 100%;
  
  
    .buttonUni{
      margin: 10px;
      left: -10px;
      width: 90%;
      padding: 10px;
      font-size: 15px;
      border-radius: 10px;
    }
  
    button {
      margin: 5px;
      width: 90%;
      font-size: 12px;
      border-radius: 5px;
    }
  }
}

// Mobile-mini
@media (max-width: 500px) {
  _::-webkit-full-page-media, _:future, :root .post {
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 95%;
  
    _::-webkit-full-page-media, _:future, :root p{
      font-size: 10px;
      bottom: 15%;
      width: 90%;
      border-radius: 15px;
      margin: auto;
      font-size: 10px;
      -webkit-text-size-adjust: 100%;
    }
  }

  .post {
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 95%;
  
    p{
      font-size: 10px;
      bottom: 15%;
      width: 90%;
      border-radius: 15px;
      margin: auto;
      font-size: 10px;
    }
  }
}


