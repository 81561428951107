.header {
  width: 100%; 
  height: 100vh;
  // background: url(../../../asset/images/IMG_0001.JPG) no-repeat 50% 50%;
  background: url(../../../asset/images/IMG_9544.JPG) no-repeat 50% 50%;
  background-attachment: fixed; 
  background-position: center; 
  background-size: cover;
}

.title_subtitle { 
  padding-top: 250px;
  background: transparent; 
  text-align: center; 
  color: #F7F7F7; 
  font-family: 'Ubuntu', sans-serif; 

  h2 {
    font-size: 45px; 
    letter-spacing: 20px;
  }

  h5 {
      font-size: 25px; 
      letter-spacing: 7px;
  }
}

a { 
  color: #F7F7F7; 
  text-decoration: none; 

  &:hover  { color: #FF2672; }
  &:active { color: #002672; }
}

.fix_me { 
  position:fixed; 
  bottom:5px; 
  left:0px; 
  font-size:12px;
}

.button      { 
  background: #002672; 
  color: #F7F7F7; 
  padding: 5px 10px;

  &:hover{ 
    background: #FF2672; 
    color: #F7F7F7; 
  }
}

.philosophy 	{ 
  width: 100%; height: 100vh;
  background: url(../../../asset/images/IMG_0005.JPG) no-repeat 50% 50%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  color: #F7F7F7;
  font-size: 20px; 
  line-height: 30px; 
  text-align: justify;

  p { 
    padding: 10px 20%;
    font-family: 'Ubuntu', sans-serif;
  }
}

.movie {
  h2 {
    font-size: 45px; 
    letter-spacing: 20px;
    color: #f7f7f7;
    font-family: 'Ubuntu', sans-serif;
  }
  width: 100%; 
  padding: 100px 0;
  background: url(../../../asset/images/IMG_0003.JPG) no-repeat 50% 50%;
  background-attachment: fixed;
  background-position: center; 
  background-size: cover;
  text-align: center;
  iframe { width: 800px; height: 450px; }
}

// Tablet
@media (max-width: 1025px) and (min-width: 501px) {
  .title_subtitle { 
    h2 { font-size: 40px; letter-spacing: 15px; }
    h5 { font-size: 20px; letter-spacing: 5px; }
  }
  .philosophy p { font-size: 15px;}
  .movie {
    h2 { font-size: 40px; letter-spacing: 15px; }
    iframe { width: 500px; height: 280px; }
  }
}

// Mobile
@media (max-width: 500px) {
	.header {
    background: url(../../../asset/images/IMG_9544_mobile.JPG) no-repeat 50% 50%;
    background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
  }
  .title_subtitle {
    h2 { font-size: 35px; letter-spacing: 10px; }
    h5 { font-size: 15px; letter-spacing: 3px; }
  }
  .philosophy { 
    background: url(../../../asset/images/IMG_0005_mobile.JPG) no-repeat 50% 50%;
    background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
    p { font-size: 15px;}
  }
  .movie {
    h2 { font-size: 35px; letter-spacing: 10px; }
    iframe { width: 90%; height: 280px;}
  }
}