.logout {
  margin: 125px 75px;
  padding: 25px 50px;
  width: 50%;
  height: 425px;
  background-color: #f7f7f7;
  position: fixed;
  border-left: solid #f44336 15px;
  overflow: auto;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 2;

  button {
    margin: 0;
    padding:0;
    width: 150px;
    height: 150px;
    background-color: white;
    border: none #e7e7e7;
    border-radius: 75px;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    
    &:hover { background-color: #e7e7e7; }
    &:focus { outline: 0; }
  }
}