.myAccountEdit {
  margin: 125px 75px;
  padding: 25px 50px;
  width: 50%;
  height: 425px;
  background-color: #f7f7f7;
  position: fixed;
  border-left: solid #4CAF50 15px;
  overflow: auto;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  
  button {
    background-color: white;
    border: 2px solid #e7e7e7;
    border-radius: 10px;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    padding: 15px 15px;

    &:hover { background-color: #e7e7e7; }
    &:focus { outline:0; }
  }

  input {
    width: 220px;
    height: 20px;
    margin-top: 7px;
    padding: 10px;
    font-family: 'Ubuntu', sans-serif;
    border: none;
    font-size: 17px;
  }
  
  textarea {
    width: 550px;
    margin-top: 7px;
    padding: 10px;
    background-color: #ffffff;
    color: #000;
    font-family: 'Ubuntu', sans-serif;
    border: none;
    font-size: 17px;
  }

  table {
    width: 100%;
    padding: 0;
    
    th {
      text-emphasis-style: bold;
      text-align: left;
    }
    
    td {
      text-align: left;
      padding: 10px;
    }
  }

}

// ::-webkit-scrollbar {
//   width: 0px;
//   background: transparent;
// }