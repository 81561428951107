.usertabs {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  margin-top:20px;
  position:fixed;
  left: 0;
  
  img {
    width: 80px;
    float: left;
    padding: 4px 15px;
  }
}

.tabs {
  width: 100%;
  color: #f7f7f7;
  overflow: hidden;
  position: fixed;

  button {
    color: #f7f7f7;
    background-color: #272822;
    border-color: #6B6B6B;
    border-bottom: transparent;
    border-right: transparent;
    border-radius: 10px;
    width: 200px;
    margin: 0 3px;
    outline: none;
    cursor: pointer;
    padding: 14px 32px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 17px;
    transition: 0.3s;
    
    &:hover {
      background-color: #6B6B6B;
    }

    &:active {
        background-color: #FF2672;
        border: none;
    }
  }
}