.creator {
  position: fixed;
  top: 0;
  left: 5%;
  margin: 15px 0 0 30px;
  h1 {
    color: #ffffff;
  }
  .inputField {
    position: fixed;
    top: 0vh;
    left: 5%;
    width: 90%;
    height: 75vh;
    margin: 85px 0;
    border-radius: 20px;
    background-color: #f7f7f7;
    overflow: scroll;
    
    select {
      font-family: 'Ubuntu', sans-serif;
      font-size: 15px;
      border: none;
      border-radius: 10px;
      &:focus { outline: 0; }
    }
  
    .pulldown {
      width: 300px;
      height: 40px;
      margin: 15px 0px 0px 30px;
      font-family: 'Ubuntu', sans-serif;
    }
  
    input {
      height: 20px;
      font-size: 15px;
      margin: 15px 0px 0px 30px;
      font-family: 'Ubuntu', sans-serif;
      border: none;
      border-radius: 10px;
      &:focus { outline: 0; }
    }

    .shortInput { width: 280px; }
    .longInput { width: 87%; }
    
    textarea {
      border: none;
      // border-left: 10px solid #444;
      border-radius: 15px;
      background-color: #fff;
      color: #000;
      margin: 15px 0px 0px 30px;
      width: 90%;
      font-size: 15px;
      font-family: 'Ubuntu', sans-serif;
      &:focus { outline: 0; }
    }
  }
  button {
    width: 100px;
    height: 40px;
    font-size: 15px;
    margin-right: 15px;
    font-family: 'Ubuntu', sans-serif;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    &:hover { opacity: 0.7; }
    &:focus { outline: 0; }
  }
  
  .buttons { 
    position: fixed;
    top: 90vh;
  }

  .lang {
    width: 40px;
    color: #000;
    border-radius: 20px;
    // background-color: #FFA719;
    background-color: #f7f7f7;
  }

  .preview { 
    background-color: #E84E17; 
  }
  .submit { 
    background-color:#3CBD09;
  }
}