.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0,0,0,0.4);
}

.box {
  position: fixed;
  width: 40%;
  height: 300px;
  top: 50%;
  left: 35%;
  z-index: 1;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  color: #000;
  border-radius: 20px;
  padding: 20px 60px;
  line-height: 2em;

  .closeButton {
    color: #aaaaaa;
    position: fixed;
    right: 30px;
    top: 25px;
    font-size: 34px;
    border: none;
    background: #f7f7f7;

    &:hover, &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
      outline:0;
    }
  }
}

.startButton {
  width: 100px;
  height: 50px;
  position: fixed;
  left: 60px;
  bottom: 40px;
  border: none;
  border-radius: 10px;
  background: #ff2672;
  color: #f7f7f7;
  
  &:hover, &:focus {
    text-decoration: none;
    background-color: #FF8CB8;
    cursor: pointer;
    outline:0;
  }
}


.show {
  display: block;
}

.hide {
  display: none;
}