.LoginPage {
  width: 100%;
  height: 100vh;
  margin-top: 70px;
  padding-top: 20px;
  background: url(../../../asset/images/IMG_0005.JPG) no-repeat 50% 50%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  text-align: center;
  color: #f7f7f7;
  font-family: 'Ubuntu', sans-serif;
}

.login {
  width: 400px;
  margin: 20px auto;
  border: solid #f7f7f7 1px;
  border-radius:40px;
}

// Mobile
@media (max-width: 500px) {
  .LoginPage { 
    background: url(../../../asset/images/IMG_0005_mobile.JPG) no-repeat 50% 50%;
    background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
  }
}
