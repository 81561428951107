.signUp {
  z-index: 0;
}

table{
  right: 0;
  margin-right: 30px;
  padding:auto;
  
  td{
    text-align:right;
  }
}

hr {
  width: 90%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white, rgba(0, 0, 0, 0));
}

input[type=text], input[type=password] {
  margin: 0;
  height: 5px;
  // padding: 5px;
  border: 1px solid #ccc;
  border-radius:10px;
  &:focus {outline: 0}
}

.help a{
  color:#9a92ff;
  text-decoration: underline;
  font-size: 11px;

  &:hover{
    opacity: 0.8;
  }
}

.button {
  background-color: #ff2672;
  border-radius:10px;
  color: #f7f7f7;
  padding: 10px 100px;
  margin: 8px 0;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    color: #f7f7f7;
  }
}

.newuser {
  display: inline-block;
  position: relative;


  &::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 50%;
    top: 50%;
    left: 100%;
    background: #f7f7f7;
  }

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 50%;
    top: 50%;
    right: 100%;
    background: #f7f7f7;
  }
}

.checkbox_parts {
  font-size: 15px;
}

.policy {
  background: transparent;
  text-decoration: underline;
  color: rgb(88, 246, 251);
  border: none;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
  &:hover {
    color: rgb(88, 246, 251);
    opacity: 0.6;
  }
}

