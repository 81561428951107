.UserCalendar {
  width: 280px;
  padding: 10px 20px;
  right: 0;
  margin-top: 380px;
  position: fixed;
  // margin-top: 120px;
  border: 0px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: -1;
  background: #f7f7f7;
  
  input, button {
    font-family: 'Ubuntu', sans-serif;
    transition: 0.2s;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background: #f7f7f7;
    &:hover { background: #ddd; }
  }
}

// Reference: https://github.com/wojtekmaj/react-calendar/tree/master/src
//            https://www.npmjs.com/package/react-calendar