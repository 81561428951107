.main_footer {
  padding: 20vh 0 3vh 0;
  font-family: 'Ubuntu', sans-serif;
  background: #000000;
  color: #f7f7f7;
  text-align: center;
  .box {
    text-align: center;
    padding: 70px 0;
    font-size: 15px;
    .icon {
      display: inline-block;
    }
  }

  a { padding: 20px; }
}

// Display icons 4x2 within this range
@media (max-width: 576px) and (min-width: 300px) {
  .box { width: 300px; margin: auto;}
}