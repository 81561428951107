.Overview {
	color: #f7f7f7;
	// font-family: 'Noto Serif JP', serif;
	font-family: 'Ubuntu', sans-serif;
	line-height: 2em;
}

/* SPLIT SCREEN */
.left {
	width: 25%;
	float: left;
	text-align: right;
	padding: 15% 0 100px 0px;
}
.right {
	width: 50%;
	float: right;
	text-align: left;
	padding: 15% 15% 100px 5%;
	line-height: 1.5em;
}

.philosophy {
	width: 100%; height: 100vh;
	text-align: center;
	background: url(../../../asset/images/replaced_bg_01.png) no-repeat 50% 50%;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;

	.words {
		width: 80%;
		position: absolute;
		top: 45%;
		left: 10%;
	}
}

.background {
	height: 100vh;
	background: url(../../../asset/images/replaced_bg_05.png) no-repeat 50% 50%;
	background-attachment: fixed;
	background-position: center; 
	background-size: cover; 
}

.meaning {
	height: 100vh;
	background: url(../../../asset/images/replaced_bg_03.png) no-repeat 50% 50%;
	background-attachment: fixed; 
	background-position: center; 
	background-size: cover; 
}

.field {
	height: 100vh;
	background: url(../../../asset/images/replaced_bg_06.png) no-repeat 50% 50%;
	background-attachment: fixed; 
	background-position: center; 
	background-size: cover; 
}

.strength {
	height: 100vh;
	background: url(../../../asset/images/replaced_bg_04.png) no-repeat 50% 50%;
	background-attachment: fixed; 
	background-position: center; 
	background-size: cover; 
}

.missions {
	height: 100vh;
	background: url(../../../asset/images/replaced_bg_02.png) no-repeat;
	background-attachment: fixed; 
	background-position: center; 
	background-size: cover; 
}

// Tablet
// @media (max-width: 1025px) and (min-width: 501px) {
// 	// same as PC is fine for overview page
// }

// Mobile
@media (max-width: 500px) {
	
	.philosophy {
		background: url(../../../asset/images/replaced_bg_01_mobile.png) no-repeat 50% 50%;
		background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
	}

	.background {
		height: 100vh;
		background: url(../../../asset/images/replaced_bg_05_mobile.png) no-repeat 50% 50%;
		background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
	}
	
	.meaning {
		height: 100vh;
		background: url(../../../asset/images/replaced_bg_03_mobile.png);
		background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
	}
	
	.field {
		height: 100vh;
		background: url(../../../asset/images/replaced_bg_06_mobile.png) no-repeat 50% 50%;
		background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
	}
	
	.strength {
		height: 100vh;
		background: url(../../../asset/images/replaced_bg_04_mobile.png) no-repeat 50% 50%;
		background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
	}
	
	.missions {
		height: 100vh;
		background: url(../../../asset/images/replaced_bg_02_mobile.png) no-repeat;
		background-position: center; 
		-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
		background-size: cover;
		z-index: -10;
	}
	
	.left {
		width: 90%;
		text-align: center; 
		float: none;
		padding: 10% 5% 0 5%;
	}
	.right { 
		width: 90%;
		text-align: justify;
		float: none;
		padding: 5% 5%;
	}
}