.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0,0,0,0.4);
}

.box {
  position: fixed;
  width: 40%;
  height: 20%;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  color: #000;
  border-radius: 20px;
  padding: 20px 60px;
  line-height: 2em;

  h1 { color: #000; }
}


.show { display: block; }
.hide { display: none; }