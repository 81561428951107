.people {
  position: absolute;
  width: 100%;
  margin-top: 60px;
  padding: 5px 0 90px 0;
  text-align: center;
  color: #ffffff;
  background: #000000;
  font-size: 20px;
  background: url(../../../asset/images/background_people.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  h2 { 
    font-family: 'Ubuntu', sans-serif; 
    font-size: 45px; 
    letter-spacing: 15px;
  }
}

.profile {
  display: inline-block;
  width: 260px;

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    height: 350px;
    border-radius: 20px;
    &:focus{ outline: 0; }
    
    .image { border-radius: 20px; }
  }

  img { width: 250px; height: 350px; }

  &:hover .imgName {
    visibility: visible;
    opacity: 1;
  }
}

.imgName {
  position: relative;
  top: -100px;
  color: #000;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.7);
  opacity: 0;
  z-index: 1;
  font-size: 20px;
  font-family: 'Ubuntu', sans-serif;
  transition: opacity .2s, visibility .2s;
}

// Tablet
@media (max-width: 1050px) and (min-width: 501px) {
  .profile { width: 182px;
    button { height: 245px; }
    img { width: 175px; height: 245px; }
    &:hover .imgName { visibility: hidden; }
  }
}

// Mobile
@media (max-width: 500px) {
  .profile { width: 105px;
    button { height: 140px; }
    img { width: 100px; height: 140px; }
    &:hover .imgName { visibility: hidden; }
  }
}