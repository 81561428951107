.usertabs {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  margin-top:20px;
  position:fixed;
}

.split {
  position: fixed;
}

.left {
  color: #f7f7f7;
  width: 70%;
  left: 0;
  
  img {
    width: 80px;
    float: left;
    padding: 4px 15px;
  }
}

.right {
  color: #f7f7f7;
  width: 320px;
  right: 0;

  input[type=text] {
    width: 220px;
    height: 20px;
    margin-top: 7px;
    padding: 10px;
    font-family: 'Ubuntu', sans-serif;
    border: none;
    font-size: 17px;
    &:focus {outline:0;}
  }

}

.tabs {
  width: 100%;
  color: #f7f7f7;
  overflow: hidden;
  position: fixed;

  button {
    color: #f7f7f7;
    background-color: #272822;
    border-color: #6B6B6B;
    border-bottom: transparent;
    border-right: transparent;
    border-radius: 10px;
    width: 200px;
    margin: 0 3px;
    outline: none;
    cursor: pointer;
    padding: 14px 32px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 17px;
    transition: 0.3s;
    
    &:hover {
      background-color: #6B6B6B;
    }

    &:active {
        background-color: #FF2672;
        border: none;
    }
  }
}

/* STYLING myAccount DROPDOWN MENU */
.dropdown {
  float: right;
  height:50px;
  position: relative;
  display: inline-block;
}

.dropbtn {
  // background-color: #272822;
  background-color: transparent;
  font-size: 16px;
  border: none;
}

// Dropdown Content (Hidden by Default)
.dropdownContent {
  right: 0;
  display: none;
  position: absolute;
  background-color: #e9e9e9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  
  // Links inside the dropdown
  a {
    width:100%;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: inline-block;

    &:hover { background-color: #ddd; }
  }
}

// Show the dropdown menu on hover
.dropdown:hover .dropdownContent {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #f7f7f7;
}