.logo {
  line-height: 60px;
  position: fixed;
  float: left;
  margin: 16px 46px 0 46px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
  z-index: 2;
}

a { 
  color: #F7F7F7; 
  text-decoration: none;

  &:hover  { 
    color: #FF2672; 
  }

  &:active { 
    color: #002672; 
  }
}


.nav { 
  width: 100%;
  line-height: 60px; 
  position: fixed;
  z-index: 1;
  
  button {
    font-family: 'Ubuntu', sans-serif;
    border: none;
    border-radius: 15px;
    background: #fff;
    height: 30px;
    width: 30px;
    cursor: pointer;
    &:hover { background: #ccc; }
    &:focus { outline: 0; }
  }

  .laptop {
    ul { 
      background: #000000; 
      color: #F7F7F7; 
      text-align: right; 
      margin: 0; 
      padding-right: 40px;

      li { 
        display: inline-block;
        padding: 0px 30px;
        font-size: 12px;
        font-family: 'Ubuntu', sans-serif;
        transition: 1s;
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;

        .login { 
          background: #002672; 
          padding: 5px 10px;
          width: 50px;
          text-align: center;
          &:hover { background: #FF2672; color: #f7f7f7; }
        }
      }
    }
  }
}

.laptop { visibility: visible; }
.mobile { visibility: hidden; }

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Mobile
@media (max-width: 850px) {
  .laptop { visibility: hidden; }
  .mobile { visibility: visible; 
    background: #000000;
    position: fixed; top: 0; right: 0;
    width: 100%; height: 30px;
    z-index: 1;
    padding: 20px;
    button { 
      background: #fff;
      float: right;
      font-size: 10px; 
    }
  }
}