.Course {
  color: white;
  background-color: #ddd;
  width: 100%;
  height: 100vh;
}

.CourseBody {
  width: 100%;
  height: 100vh;
  margin-top: 60px;
  position: fixed;
  z-index: 0;
}

.buttonController{
  padding: 0 0 10px 0;
  color: #000;
  background: #ddd;
  width: 100%;
  height: 40px;
  position: fixed;
}

.compilerButton {
  margin: 10px 10%;
  width: 80%;
  height: 40px;
  color: white;
  border: none;
  border-radius: 20px;
  background-color: #1B1464;
  z-index: 1;
  cursor: pointer;
  &:hover { background-color: #3125B8; }
  &:focus { outline: 0; }
}

.content {
  width: 200px;
  background-color: #1B1464;
  float: left;
  position: absolute;
  overflow: auto;
  padding: 10px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  animation-name: run;
  animation-duration: 0.5s;

  button {
    width: 190px;
    padding: 10px;
    color: #ffffff;
    background-color: #1B1464;
    font-family: 'Ubuntu', sans-serif;
    font-size: 15px;
    text-align: left;
    border: none;
    // border: red 1px solid;
    border-radius: 10px;
    cursor: pointer;
    &:hover { background-color: #3125B8; }
    &:focus { outline: 0; }
  }
}

.language_dropdown {
  margin: 0 4px;
  width: 100%;
  height: 40px;
  border: solid grey 0px;
  color: #fff;
  background-color: #16457A;
  font-family: 'Ubuntu', sans-serif;
  cursor: pointer;
  &:hover { background-color: #ccc; color: #000; }
  &:focus { outline: 0; }
}

.language_dropdown1 {
  margin: 0 4px;
  width: 100%;
  height: 40px;
  border: solid grey 0px;
  border-radius: 20px;
  color: #fff;
  background-color: #16457A;
  font-family: 'Ubuntu', sans-serif;
  cursor: pointer;
  &:hover { background-color: #ccc; color: #000; }
  &:focus { outline: 0; }
}

.move {
  // background-color: #26A7C7;
  background-color: #126870;
  cursor: pointer;
  // &:hover { background-color: #239AB8; }
  &:hover { background-color: #17858F; }
  &:focus { outline: 0; }
}

.run {
  // background-color: #00BF0A;
  background-color: #117039;
  cursor: pointer;
  &:hover { background-color: #179C4E; }
  &:focus { outline: 0; }
}

.show {
  // background-color: #00BF0A;
  background-color: #AD880E;
  cursor: pointer;
  &:hover { background-color: #DEAE12; }
  &:focus { outline: 0; }
}

.reset {
  // background-color: #FF2672;
  background-color: #701C11;
  cursor: pointer;
  &:hover { background-color: #992617; }
  &:focus { outline: 0; }
}

.hide {
  font-family: 'Ubuntu', sans-serif;
  width: 100%;
  color: #000;
  background: transparent;
  border: none;
  cursor: pointer;
  &:focus { outline: 0; }
}

.instruction {
  float: left;
  height: 100%;
  background-color: white;
  color: black;
  overflow: auto;
}

.gridCtrlVertical {
  display:inline-block;
  background-color: #ddd;
  height: 100%;
  cursor: col-resize;
}

.gridCtrlHorizontal {
  display:inline-block;
  width: 100%;
  background-color: #ddd;
  cursor: row-resize;
}

.editor {
  float: right;
  isolation: isolate;
  z-index: -1;
}

.console {
  margin: 0;
  height: 180px;
  float: right;
  background-color: rgb(78, 77, 83);
  overflow: auto;

  div { display: inline-block; }
}

.consoleButton {
  color: snow;
  margin: 10px 10px 5px 10px;
  display: inline-block;
  cursor: pointer;
}

textarea {
  height: 130px; // have to adjust
  background-color: rgb(2, 20, 12); 
  margin: 10px;
  color: lime;
  width: 95%;
  resize: vertical;
}


@keyframes run {
  0% { left: -20%;}
  100%{ left: 0;}
}